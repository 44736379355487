import React from "react";

const Loading: React.FC = () => {
  return (
    <div className="m-loading">
      <div className="m-loading_wrapper">
        <div className="m-loading_circle" />
      </div>
    </div>
  );
};
export default Loading;
