import axios from "axios";

const API_URL = "https://cms.pgpm.3forcom.org/api/v1/";

const getStoreList = async (
  program_code: string
): Promise<ShopResponseType> => {
  const res = await axios.get(API_URL + `shops?program_code=${program_code}`);
  return res.data;
};

export const updateStoreList = async (id: number, params: UpdateShopType) => {
  const res = await axios.put(API_URL + `shops/${id}`, params);
  return res.data;
};

export const getProgramList = async (): Promise<ProgramResponseType> => {
  const res = await axios.get(API_URL + "programs");
  return res.data;
};

export default getStoreList;
