import React from "react";


interface NotifyProps {
  show?: boolean;
  message?: string;
}

const Notify: React.FC<NotifyProps> = ({ show, message }) => (
  <div className={show ? "m-notify m-notify-show" : "m-notify"}>
    <div className="m-notify_layer" />
    <div className="m-notify_body">
      <div className="m-notify_icon" />
      {
        message && (
          <div className="m-notify_message">
            <p>
              {message}
            </p>
          </div>
        )
      }

    </div>
  </div>
);

Notify.defaultProps = {
  show: true,
};

export default Notify;
