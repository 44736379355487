/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from "react";
import Select, {
  components,
  GroupedOptionsType,
  OptionsType,
  Props as SelectProps,
  ValueType,
} from "react-select";

import mapModifiers from "utils/functions";

export type PulldownModifiers = "default" | "primary" | "secondary" | "extra";

export interface OptionType {
  id: number;
  label: string;
  value: string;
}

export interface PulldownProps extends SelectProps {
  modifier: PulldownModifiers;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  placeholder?: string;
  name: string;
  options: OptionsType<any> | GroupedOptionsType<any>;
  value?: OptionType;
  isSearchable?: boolean;
  isRtl?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isHideIconDropdown?: boolean;
  handleChange?: (value: ValueType<OptionType, false>) => void;
  innerRef?: React.MutableRefObject<any>;
  error?: string;
}

const Pulldown = forwardRef<HTMLDivElement, PulldownProps>(
  ({
    modifier,
    minMenuHeight,
    maxMenuHeight,
    placeholder,
    name,
    value,
    options,
    isSearchable,
    isRtl,
    isLoading,
    isClearable,
    isHideIconDropdown,
    innerRef,
    error,
    handleChange,
    ...innerProps
  }) => {
    const [selectedOption] = useState<OptionType | null>(null);

    const DropdownIndicator = (props: any) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.DropdownIndicator {...props}>
        <div className={`m-pullDown-${modifier}_icon-indicator`} />
      </components.DropdownIndicator>
    );

    const CustomStyles = {
      option: (
        provided: any,
        state: { isFocused: boolean; isSelected: boolean }
      ) => ({
        ...provided,
        border: "none",
        padding: 12,
        backgroundColor:
          state.isFocused || state.isSelected ? "#f4f6fa" : "transparent",
        color: "black",
        "&:active": {
          backgroundColor: "#fafafa",
        },
      }),
      control: () => ({
        borderColor: error ? "red" : "#ddd",
      }),
      indicatorSeparator: () => ({ display: "none" }),
      placeholder: () => ({}),
      singleValue: (provided: any, state: { isDisabled: boolean }) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        return { opacity, transition };
      },
      valueContainer: (provided: any) => ({ ...provided, padding: 0 }),
      indicatorsContainer: (provided: any) => ({ ...provided, padding: 0 }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: 0,
        display: isHideIconDropdown ? "none" : "block",
      }),
      menu: (provided: any) => ({ ...provided, marginTop: 0 }),
      menuList: (provided: any) => ({ ...provided }),
    };

    const CustomTheme = (theme: any) => ({
      ...theme,
      borderRadius: 1,
      colors: {
        ...theme.colors,
      },
    });

    const handleChangeOption = (option: any) => {
      if (handleChange) {
        handleChange(option);
      }
    };

    return (
      <div className="m-pullDown">
        <Select
          className="m-pullDown"
          classNamePrefix={mapModifiers("m-pullDown", modifier)}
          styles={CustomStyles}
          components={{ DropdownIndicator }}
          defaultValue={selectedOption}
          onChange={handleChangeOption}
          options={options}
          placeholder={placeholder}
          minMenuHeight={minMenuHeight}
          maxMenuHeight={maxMenuHeight}
          name={name}
          value={value}
          isRtl={isRtl}
          isSearchable={isSearchable}
          isLoading={isLoading}
          isClearable={isClearable}
          theme={CustomTheme}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...innerProps}
        />
        {error && <div className="m-pullDown_error">{error}</div>}
      </div>
    );
  }
);

export default Pulldown;
