/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, forwardRef } from "react";

import mapModifiers from "utils/functions";

export type typeModifiersInput = ("primary")[];

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  placeholder?: string;
  name: string;
  id?: string;
  defaultValue?: string;
  disabled?: boolean;
  modifiers?: typeModifiersInput;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;

  error?: string;
}
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({
    type,
    placeholder,
    name,
    id,
    defaultValue,
    disabled,
    modifiers,
    handleChange,
    error,
    ...rest
  }, ref) => (
    <div className={mapModifiers("a-input", modifiers)}>
      <input
        className={mapModifiers("a-input_input", error ? ["error"] : [])}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleChange}
        autoComplete="off"
        ref={ref}
        {...rest}
      />
      {error && <div className="a-input_error">{error}</div>}
    </div>
  ),
);

export default Input;

