import React from "react";
import "./App.scss";
import Container from "components/organisms/Container";
import IntroTemplate from "components/templates/IntroTemplate";
import logo from "assets/images/logo.png";

function App() {
  return (
    <div className="App">
      <Container modifiers={["fullSreen"]}>
        <div className="app_wrapTitle">
          <img src={logo} alt="PGPM" className="app_logo" />
        </div>
      </Container>
      <IntroTemplate />
    </div>
  );
}

export default App;
