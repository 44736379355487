import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ValueType } from "react-select/src/types";

import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import Pulldown, { OptionType } from "components/atoms/PullDown";
import Container from "components/organisms/Container";
import getStoreList, { getProgramList, updateStoreList } from "services/shop";
import Notify from "components/molecules/Notify";
import Loading from "components/atoms/Loading";

type ShopFormDataType = {
  storeName: string;
  storeAddress: string;
  program: ValueType<OptionType, false>;
  store: ValueType<OptionType, false>;
};

const IntroTemplate: React.FC = () => {
  const [programOption, setProgramOption] = useState<OptionType[]>([]);
  const [programSelected, setProgramSelected] = useState<OptionType | null>();
  const [storeOption, setStoreOption] = useState<OptionType[]>([]);
  const [storeSelected, setStoreSelected] = useState<OptionType | null>();
  const [dataShop, setDataShop] = useState<ShopResponseDataType[]>([]);
  const [dataProgram, setDataProgram] = useState<ProgramResponseDataType[]>([]);
  const [storeName, setStoreName] = useState<string>();
  const [storeAddress, setStoreAddress] = useState<string>();
  const [isError, setIsError] = useState<string | undefined>();
  const [showNotify, setShowNotify] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
  } = useForm<ShopFormDataType>();
  const onSubmit = (data: ShopFormDataType) => {
    setIsError(undefined);

    if (window.navigator.geolocation) {
      setLoading(true);
      window.navigator.geolocation.getCurrentPosition(
        async (locate) => {
          if (data.store && data.store.id && programSelected?.value) {
            await updateStoreList(data.store.id, {
              latitude: locate.coords.latitude.toString(),
              longitude: locate.coords.longitude.toString(),
              store_name: data.storeName,
              address: data.storeAddress,
              program_code: programSelected?.value,
            });
            setShowNotify(true);
            setTimeout(() => {
              setShowNotify(false);
            }, 3000);
            setStoreName("");
            setStoreAddress("");
            setStoreSelected(null);
            setProgramSelected(null);
            control.setValue("store", null);
            reset();
            setLoading(false);
            await getStore();
          }
        },
        (err) => setIsError(err.message)
      );
    }
  };
  async function getStore() {
    if (programSelected?.value) {
      const result = await getStoreList(programSelected?.value);
      const filter = (await result.data.map((val) => ({
        id: val.id,
        value: val.code,
        label: `${val.name} (${val.code})`,
      }))) as OptionType[];
      setStoreOption(filter);
      setDataShop(result.data);
    }
  }
  async function getPrograms() {
    const result = await getProgramList();
    const filter = (await result.data.map((val) => ({
      id: val.id,
      value: val.code,
      label: val.name,
    }))) as OptionType[];
    setProgramOption(filter);
    setDataProgram(result.data);
  }
  useEffect(() => {
    getPrograms();
  }, []);
  useEffect(() => {
    setStoreSelected(null);
    setStoreName("");
    setStoreAddress("");
    getStore();
  }, [programSelected]);
  const filterShopResult = (value: ValueType<OptionType, false>) => {
    if (value) {
      const result = dataShop.find((a) => a.id === value.id);
      setStoreName(result?.name);
      setStoreAddress(result?.address);
    }
    control.setValue("store", value);
  };
  return (
    <div className="t-introTemplate">
      {loading && <Loading />}
      <Notify show={showNotify} message="Cập nhật thành công !" />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="t-introTemplate_form">
            <div className="t-introTemplate_pulldown">
              <Controller
                render={() => (
                  <Pulldown
                    options={programOption}
                    name="program"
                    modifier="extra"
                    placeholder="Chương trình..."
                    value={programSelected}
                    handleChange={(value: ValueType<OptionType, false>) => {
                      setIsError(undefined);
                      if (value) {
                        setProgramSelected(value);
                      }
                    }}
                  />
                )}
                defaultValue={null}
                control={control}
                name="program"
              />
            </div>
            <div className="t-introTemplate_pulldown">
              <Controller
                render={() => (
                  <Pulldown
                    options={storeOption}
                    name="store"
                    modifier="extra"
                    placeholder="Cửa hàng..."
                    value={storeSelected}
                    handleChange={(value: ValueType<OptionType, false>) => {
                      setIsError(undefined);
                      if (value) {
                        filterShopResult(value);
                        setStoreSelected(value);
                      }
                    }}
                  />
                )}
                defaultValue={null}
                control={control}
                name="store"
              />
            </div>
            <div className="t-introTemplate_input">
              <Input
                name="storeName"
                placeholder="Tên Cửa Hàng"
                value={storeName}
                modifiers={["primary"]}
                handleChange={(e) => {
                  setStoreName(e.target.value);
                }}
                ref={register}
              />
            </div>
            <div className="t-introTemplate_input">
              <Input
                name="storeAddress"
                placeholder="Địa chỉ"
                modifiers={["primary"]}
                value={storeAddress}
                handleChange={(e) => {
                  setStoreAddress(e.target.value);
                }}
                ref={register}
              />
            </div>
            <div style={{ position: "relative" }}>
              {isError && (
                <div className="t-introTemplate_error">
                  <p>
                    Bạn cần cho phép sử dụng vị trí hiện tại! Vui lòng bật vị
                    trí trong cài đặt trình duyệt
                  </p>
                </div>
              )}
            </div>

            <div className="t-introTemplate_button">
              <Button
                variant="spaceCadet"
                size="md"
                type="submit"
                modifiers={["filled", "bold"]}
              >
                Cập nhật
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default IntroTemplate;
