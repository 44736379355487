import React from "react";
import mapModifiers from "utils/functions";

type ContainerProps = {
  modifiers?: ('fullSreen')[]
}
const Container: React.FC<ContainerProps> = ({
  children,
  modifiers
}) => (
  <div
    className={mapModifiers("o-container", modifiers)}
  >
    {children}
  </div>
);

export default Container;
