import React from "react";

import mapModifiers from "utils/functions";

export type ButtonModifier = (
  | "filled"
  | "outlined"
  | "text"
  | "sm"
  | "lg"
  | "bold"
  | "uppercase"
  | "center"
  | "medium"
  | GeneralTextProps
)[];

interface ButtonProps {
  size?: "sm" | "md";
  name?: string;
  type?: "button" | "submit",
  disabled?: boolean;
  modifiers?: ButtonModifier,
  variant?: | StyleColor | StyleText,
  handleClick?: () => void
}

const Button: React.FC<ButtonProps> = ({
  size,
  name,
  type,
  variant,
  disabled,
  modifiers,
  children,
  handleClick,
}) => (
  <button
    type={type}
    className={mapModifiers(
      "a-button",
      modifiers,
      variant,
      size,
    )}
    name={name}
    disabled={disabled}
    onClick={handleClick}
  >
    {children}
  </button>
);

Button.defaultProps = {
  type: "button",
  variant: "spaceCadet",
  modifiers: ["outlined"],
};

export default Button;
